
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { parseTime } from "@/utils";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getNewbieList, exportNewbieList } from "@/api/request/bigdata";

//组件
@Component({
  name: "NewBieDiscount",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private total: number = 0; //总数
  private list: any[] = []; //列表数据
  private listLoading: boolean = true; // 列表加载

  //请求数据
  private listQuery: any = {
    //页码
    page: 1,
    page_size: 20,

    //临时数据
    time: [], //时间
    user_id_str: "", //用户ID

    //数据
    user_id: 0, // 用户ID
    nick_name: "", //用户昵称
    user_type: 0, //用户类型：0-全部 1-正常用户 2-内部用户 3-自由博主 4-签约博主

    //时间数据
    end_time: "", //注册结束时间，格式：2024-03-12 00:00:00
    begin_time: "", //注册开始时间，格式：2024-03-12 00:00:00
  };

  //用户类型列表
  private userTypeList: any[] = [
    { id: 0, name: "全部" },
    { id: 1, name: "正常用户" },
    { id: 2, name: "内部用户" },
    { id: 3, name: "自由博主" },
    { id: 4, name: "签约博主" },
  ];

  //创建调用
  created() {
    //获取列表
    this.getList();
  }

  // //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //时间赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
      this.listQuery.begin_time = `${this.listQuery.time[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.begin_time = "";
    }

    //数据赋值
    this.listQuery.user_id = Number(this.listQuery.user_id_str);

    //获取数据
    const { data } = await getNewbieList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理导出
  private async exportHandler() {
    //显示等待
    this.listLoading = true;

    //时间赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
      this.listQuery.begin_time = `${this.listQuery.time[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.begin_time = "";
    }

    //数据赋值
    this.listQuery.user_id = Number(this.listQuery.user_id_str);

    //获取数据
    const { data } = await exportNewbieList(this.listQuery);

    //保存数据
    saveAs(data, "新手 72H 优惠列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //获取时间
  private getTimeStr(time: number): any {
    return parseTime(time * 1000);
  }
}
